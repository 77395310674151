:root {
  --bs-primary: #E50914;
  --bs-primary-rgb: 229, 9, 20;
  --bs-primary-bg-subtle: rgb(249.8, 205.8, 208);
  --bs-primary-border-subtle: rgb(244.6, 156.6, 161);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(183.2, 7.2, 16);
  --bs-primary-hover-border: rgb(160.3, 6.3, 14);
  --bs-primary-active-bg: rgb(183.2, 7.2, 16);
  --bs-primary-active-border: rgb(160.3, 6.3, 14);
  --bs-secondary: #f44336;
  --bs-secondary-rgb: 244, 67, 54;
  --bs-secondary-bg-subtle: rgb(252.8, 217.4, 214.8);
  --bs-secondary-border-subtle: rgb(250.6, 179.8, 174.6);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(195.2, 53.6, 43.2);
  --bs-secondary-hover-border: rgb(170.8, 46.9, 37.8);
  --bs-secondary-active-bg: rgb(195.2, 53.6, 43.2);
  --bs-secondary-active-border: rgb(170.8, 46.9, 37.8);
  --bs-success: #4caf50;
  --bs-success-rgb: 76, 175, 80;
  --bs-success-bg-subtle: rgb(219.2, 239, 220);
  --bs-success-border-subtle: rgb(183.4, 223, 185);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(60.8, 140, 64);
  --bs-success-hover-border: rgb(53.2, 122.5, 56);
  --bs-success-active-bg: rgb(60.8, 140, 64);
  --bs-success-active-border: rgb(53.2, 122.5, 56);
  --bs-warning: #FF9800;
  --bs-warning-rgb: 255, 152, 0;
  --bs-warning-bg-subtle: rgb(255, 234.4, 204);
  --bs-warning-border-subtle: rgb(255, 213.8, 153);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(204, 121.6, 0);
  --bs-warning-hover-border: rgb(178.5, 106.4, 0);
  --bs-warning-active-bg: rgb(204, 121.6, 0);
  --bs-warning-active-border: rgb(178.5, 106.4, 0);
  --bs-danger: #d71c1c;
  --bs-danger-rgb: 215, 28, 28;
  --bs-danger-bg-subtle: rgb(247, 209.6, 209.6);
  --bs-danger-border-subtle: rgb(239, 164.2, 164.2);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(172, 22.4, 22.4);
  --bs-danger-hover-border: rgb(150.5, 19.6, 19.6);
  --bs-danger-active-bg: rgb(172, 22.4, 22.4);
  --bs-danger-active-border: rgb(150.5, 19.6, 19.6);
  --bs-info: #2196f3;
  --bs-info-rgb: 33, 150, 243;
  --bs-info-bg-subtle: rgb(210.6, 234, 252.6);
  --bs-info-border-subtle: rgb(166.2, 213, 250.2);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(26.4, 120, 194.4);
  --bs-info-hover-border: rgb(23.1, 105, 170.1);
  --bs-info-active-bg: rgb(26.4, 120, 194.4);
  --bs-info-active-border: rgb(23.1, 105, 170.1);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-gray: #dfdddd;
  --bs-gray-rgb: 223, 221, 221;
  --bs-gray-bg-subtle: rgb(248.6, 248.2, 248.2);
  --bs-gray-border-subtle: rgb(242.2, 241.4, 241.4);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(178.4, 176.8, 176.8);
  --bs-gray-hover-border: rgb(156.1, 154.7, 154.7);
  --bs-gray-active-bg: rgb(178.4, 176.8, 176.8);
  --bs-gray-active-border: rgb(156.1, 154.7, 154.7);
  --bs-gray-dark: #343a40;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-gray-dark-bg-subtle: rgb(214.4, 215.6, 216.8);
  --bs-gray-dark-border-subtle: rgb(173.8, 176.2, 178.6);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(41.6, 46.4, 51.2);
  --bs-gray-dark-hover-border: rgb(36.4, 40.6, 44.8);
  --bs-gray-dark-active-bg: rgb(41.6, 46.4, 51.2);
  --bs-gray-dark-active-border: rgb(36.4, 40.6, 44.8);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
}
:root[data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(239.4, 107.4, 114);
  --bs-primary-bg-subtle: rgb(45.8, 1.8, 4);
}
:root[data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(248.4, 142.2, 134.4);
  --bs-secondary-bg-subtle: rgb(48.8, 13.4, 10.8);
}
:root[data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(147.6, 207, 150);
  --bs-success-bg-subtle: rgb(15.2, 35, 16);
}
:root[data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(255, 193.2, 102);
  --bs-warning-bg-subtle: rgb(51, 30.4, 0);
}
:root[data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(231, 118.8, 118.8);
  --bs-danger-bg-subtle: rgb(43, 5.6, 5.6);
}
:root[data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(121.8, 192, 247.8);
  --bs-info-bg-subtle: rgb(6.6, 30, 48.6);
}
:root[data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
:root[data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
:root[data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(235.8, 234.6, 234.6);
  --bs-gray-bg-subtle: rgb(44.6, 44.2, 44.2);
}
:root[data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(133.2, 136.8, 140.4);
  --bs-gray-dark-bg-subtle: rgb(10.4, 11.6, 12.8);
}
:root[data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-1] {
  --bs-primary: #00C3F9;
  --bs-primary-rgb: 0, 195, 249;
  --bs-primary-bg-subtle: rgb(204, 243, 253.8);
  --bs-primary-border-subtle: rgb(153, 231, 252.6);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(0, 156, 199.2);
  --bs-primary-hover-border: rgb(0, 136.5, 174.3);
  --bs-primary-active-bg: rgb(0, 156, 199.2);
  --bs-primary-active-border: rgb(0, 136.5, 174.3);
  --bs-secondary: #573BFF;
  --bs-secondary-rgb: 87, 59, 255;
  --bs-secondary-bg-subtle: rgb(221.4, 215.8, 255);
  --bs-secondary-border-subtle: rgb(187.8, 176.6, 255);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(69.6, 47.2, 204);
  --bs-secondary-hover-border: rgb(60.9, 41.3, 178.5);
  --bs-secondary-active-bg: rgb(69.6, 47.2, 204);
  --bs-secondary-active-border: rgb(60.9, 41.3, 178.5);
  --bs-success: #0D953E;
  --bs-success-rgb: 13, 149, 62;
  --bs-success-bg-subtle: rgb(206.6, 233.8, 216.4);
  --bs-success-border-subtle: rgb(158.2, 212.6, 177.8);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(10.4, 119.2, 49.6);
  --bs-success-hover-border: rgb(9.1, 104.3, 43.4);
  --bs-success-active-bg: rgb(10.4, 119.2, 49.6);
  --bs-success-active-border: rgb(9.1, 104.3, 43.4);
  --bs-warning: #EC7D10;
  --bs-warning-rgb: 236, 125, 16;
  --bs-warning-bg-subtle: rgb(251.2, 229, 207.2);
  --bs-warning-border-subtle: rgb(247.4, 203, 159.4);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(188.8, 100, 12.8);
  --bs-warning-hover-border: rgb(165.2, 87.5, 11.2);
  --bs-warning-active-bg: rgb(188.8, 100, 12.8);
  --bs-warning-active-border: rgb(165.2, 87.5, 11.2);
  --bs-danger: #E30000;
  --bs-danger-rgb: 227, 0, 0;
  --bs-danger-bg-subtle: rgb(249.4, 204, 204);
  --bs-danger-border-subtle: rgb(243.8, 153, 153);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(181.6, 0, 0);
  --bs-danger-hover-border: rgb(158.9, 0, 0);
  --bs-danger-active-bg: rgb(181.6, 0, 0);
  --bs-danger-active-border: rgb(158.9, 0, 0);
  --bs-info: #07A2C7;
  --bs-info-rgb: 7, 162, 199;
  --bs-info-bg-subtle: rgb(205.4, 236.4, 243.8);
  --bs-info-border-subtle: rgb(155.8, 217.8, 232.6);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(5.6, 129.6, 159.2);
  --bs-info-hover-border: rgb(4.9, 113.4, 139.3);
  --bs-info-active-bg: rgb(5.6, 129.6, 159.2);
  --bs-info-active-border: rgb(4.9, 113.4, 139.3);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-gray: #C2C2C2;
  --bs-gray-rgb: 194, 194, 194;
  --bs-gray-bg-subtle: rgb(242.8, 242.8, 242.8);
  --bs-gray-border-subtle: rgb(230.6, 230.6, 230.6);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(155.2, 155.2, 155.2);
  --bs-gray-hover-border: rgb(135.8, 135.8, 135.8);
  --bs-gray-active-bg: rgb(155.2, 155.2, 155.2);
  --bs-gray-active-border: rgb(135.8, 135.8, 135.8);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(102, 219, 251.4);
  --bs-primary-bg-subtle: rgb(0, 39, 49.8);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(154.2, 137.4, 255);
  --bs-secondary-bg-subtle: rgb(17.4, 11.8, 51);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(109.8, 191.4, 139.2);
  --bs-success-bg-subtle: rgb(2.6, 29.8, 12.4);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(243.6, 177, 111.6);
  --bs-warning-bg-subtle: rgb(47.2, 25, 3.2);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(238.2, 102, 102);
  --bs-danger-bg-subtle: rgb(45.4, 0, 0);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(106.2, 199.2, 221.4);
  --bs-info-bg-subtle: rgb(1.4, 32.4, 39.8);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(218.4, 218.4, 218.4);
  --bs-gray-bg-subtle: rgb(38.8, 38.8, 38.8);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-1][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-2] {
  --bs-primary: #91969E;
  --bs-primary-rgb: 145, 150, 158;
  --bs-primary-bg-subtle: rgb(233, 234, 235.6);
  --bs-primary-border-subtle: rgb(211, 213, 216.2);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(116, 120, 126.4);
  --bs-primary-hover-border: rgb(101.5, 105, 110.6);
  --bs-primary-active-bg: rgb(116, 120, 126.4);
  --bs-primary-active-border: rgb(101.5, 105, 110.6);
  --bs-secondary: #FD8D00;
  --bs-secondary-rgb: 253, 141, 0;
  --bs-secondary-bg-subtle: rgb(254.6, 232.2, 204);
  --bs-secondary-border-subtle: rgb(254.2, 209.4, 153);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(202.4, 112.8, 0);
  --bs-secondary-hover-border: rgb(177.1, 98.7, 0);
  --bs-secondary-active-bg: rgb(202.4, 112.8, 0);
  --bs-secondary-active-border: rgb(177.1, 98.7, 0);
  --bs-success: #18B55F;
  --bs-success-rgb: 24, 181, 95;
  --bs-success-bg-subtle: rgb(208.8, 240.2, 223);
  --bs-success-border-subtle: rgb(162.6, 225.4, 191);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(19.2, 144.8, 76);
  --bs-success-hover-border: rgb(16.8, 126.7, 66.5);
  --bs-success-active-bg: rgb(19.2, 144.8, 76);
  --bs-success-active-border: rgb(16.8, 126.7, 66.5);
  --bs-warning: #DB7817;
  --bs-warning-rgb: 219, 120, 23;
  --bs-warning-bg-subtle: rgb(247.8, 228, 208.6);
  --bs-warning-border-subtle: rgb(240.6, 201, 162.2);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(175.2, 96, 18.4);
  --bs-warning-hover-border: rgb(153.3, 84, 16.1);
  --bs-warning-active-bg: rgb(175.2, 96, 18.4);
  --bs-warning-active-border: rgb(153.3, 84, 16.1);
  --bs-danger: #F53030;
  --bs-danger-rgb: 245, 48, 48;
  --bs-danger-bg-subtle: rgb(253, 213.6, 213.6);
  --bs-danger-border-subtle: rgb(251, 172.2, 172.2);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(196, 38.4, 38.4);
  --bs-danger-hover-border: rgb(171.5, 33.6, 33.6);
  --bs-danger-active-bg: rgb(196, 38.4, 38.4);
  --bs-danger-active-border: rgb(171.5, 33.6, 33.6);
  --bs-info: #05B2DC;
  --bs-info-rgb: 5, 178, 220;
  --bs-info-bg-subtle: rgb(205, 239.6, 248);
  --bs-info-border-subtle: rgb(155, 224.2, 241);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(4, 142.4, 176);
  --bs-info-hover-border: rgb(3.5, 124.6, 154);
  --bs-info-active-bg: rgb(4, 142.4, 176);
  --bs-info-active-border: rgb(3.5, 124.6, 154);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-gray: #BBBBBB;
  --bs-gray-rgb: 187, 187, 187;
  --bs-gray-bg-subtle: rgb(241.4, 241.4, 241.4);
  --bs-gray-border-subtle: rgb(227.8, 227.8, 227.8);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(149.6, 149.6, 149.6);
  --bs-gray-hover-border: rgb(130.9, 130.9, 130.9);
  --bs-gray-active-bg: rgb(149.6, 149.6, 149.6);
  --bs-gray-active-border: rgb(130.9, 130.9, 130.9);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(189, 192, 196.8);
  --bs-primary-bg-subtle: rgb(29, 30, 31.6);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(253.8, 186.6, 102);
  --bs-secondary-bg-subtle: rgb(50.6, 28.2, 0);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(116.4, 210.6, 159);
  --bs-success-bg-subtle: rgb(4.8, 36.2, 19);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(233.4, 174, 115.8);
  --bs-warning-bg-subtle: rgb(43.8, 24, 4.6);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(249, 130.8, 130.8);
  --bs-danger-bg-subtle: rgb(49, 9.6, 9.6);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(105, 208.8, 234);
  --bs-info-bg-subtle: rgb(1, 35.6, 44);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(214.2, 214.2, 214.2);
  --bs-gray-bg-subtle: rgb(37.4, 37.4, 37.4);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-2][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-3] {
  --bs-primary: #DB5363;
  --bs-primary-rgb: 219, 83, 99;
  --bs-primary-bg-subtle: rgb(247.8, 220.6, 223.8);
  --bs-primary-border-subtle: rgb(240.6, 186.2, 192.6);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(175.2, 66.4, 79.2);
  --bs-primary-hover-border: rgb(153.3, 58.1, 69.3);
  --bs-primary-active-bg: rgb(175.2, 66.4, 79.2);
  --bs-primary-active-border: rgb(153.3, 58.1, 69.3);
  --bs-secondary: #366AF0;
  --bs-secondary-rgb: 54, 106, 240;
  --bs-secondary-bg-subtle: rgb(214.8, 225.2, 252);
  --bs-secondary-border-subtle: rgb(174.6, 195.4, 249);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(43.2, 84.8, 192);
  --bs-secondary-hover-border: rgb(37.8, 74.2, 168);
  --bs-secondary-active-bg: rgb(43.2, 84.8, 192);
  --bs-secondary-active-border: rgb(37.8, 74.2, 168);
  --bs-success: #16DB65;
  --bs-success-rgb: 22, 219, 101;
  --bs-success-bg-subtle: rgb(208.4, 247.8, 224.2);
  --bs-success-border-subtle: rgb(161.8, 240.6, 193.4);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(17.6, 175.2, 80.8);
  --bs-success-hover-border: rgb(15.4, 153.3, 70.7);
  --bs-success-active-bg: rgb(17.6, 175.2, 80.8);
  --bs-success-active-border: rgb(15.4, 153.3, 70.7);
  --bs-warning: #F79256;
  --bs-warning-rgb: 247, 146, 86;
  --bs-warning-bg-subtle: rgb(253.4, 233.2, 221.2);
  --bs-warning-border-subtle: rgb(251.8, 211.4, 187.4);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(197.6, 116.8, 68.8);
  --bs-warning-hover-border: rgb(172.9, 102.2, 60.2);
  --bs-warning-active-bg: rgb(197.6, 116.8, 68.8);
  --bs-warning-active-border: rgb(172.9, 102.2, 60.2);
  --bs-danger: #F0544F;
  --bs-danger-rgb: 240, 84, 79;
  --bs-danger-bg-subtle: rgb(252, 220.8, 219.8);
  --bs-danger-border-subtle: rgb(249, 186.6, 184.6);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(192, 67.2, 63.2);
  --bs-danger-hover-border: rgb(168, 58.8, 55.3);
  --bs-danger-active-bg: rgb(192, 67.2, 63.2);
  --bs-danger-active-border: rgb(168, 58.8, 55.3);
  --bs-info: #2E93B9;
  --bs-info-rgb: 46, 147, 185;
  --bs-info-bg-subtle: rgb(213.2, 233.4, 241);
  --bs-info-border-subtle: rgb(171.4, 211.8, 227);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(36.8, 117.6, 148);
  --bs-info-hover-border: rgb(32.2, 102.9, 129.5);
  --bs-info-active-bg: rgb(36.8, 117.6, 148);
  --bs-info-active-border: rgb(32.2, 102.9, 129.5);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-gray: #B3B3B3;
  --bs-gray-rgb: 179, 179, 179;
  --bs-gray-bg-subtle: rgb(239.8, 239.8, 239.8);
  --bs-gray-border-subtle: rgb(224.6, 224.6, 224.6);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(143.2, 143.2, 143.2);
  --bs-gray-hover-border: rgb(125.3, 125.3, 125.3);
  --bs-gray-active-bg: rgb(143.2, 143.2, 143.2);
  --bs-gray-active-border: rgb(125.3, 125.3, 125.3);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(233.4, 151.8, 161.4);
  --bs-primary-bg-subtle: rgb(43.8, 16.6, 19.8);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(134.4, 165.6, 246);
  --bs-secondary-bg-subtle: rgb(10.8, 21.2, 48);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(115.2, 233.4, 162.6);
  --bs-success-bg-subtle: rgb(4.4, 43.8, 20.2);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(250.2, 189.6, 153.6);
  --bs-warning-bg-subtle: rgb(49.4, 29.2, 17.2);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(246, 152.4, 149.4);
  --bs-danger-bg-subtle: rgb(48, 16.8, 15.8);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(129.6, 190.2, 213);
  --bs-info-bg-subtle: rgb(9.2, 29.4, 37);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(209.4, 209.4, 209.4);
  --bs-gray-bg-subtle: rgb(35.8, 35.8, 35.8);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-3][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-4] {
  --bs-primary: #EA6A12;
  --bs-primary-rgb: 234, 106, 18;
  --bs-primary-bg-subtle: rgb(250.8, 225.2, 207.6);
  --bs-primary-border-subtle: rgb(246.6, 195.4, 160.2);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(187.2, 84.8, 14.4);
  --bs-primary-hover-border: rgb(163.8, 74.2, 12.6);
  --bs-primary-active-bg: rgb(187.2, 84.8, 14.4);
  --bs-primary-active-border: rgb(163.8, 74.2, 12.6);
  --bs-secondary: #6410F1;
  --bs-secondary-rgb: 100, 16, 241;
  --bs-secondary-bg-subtle: rgb(224, 207.2, 252.2);
  --bs-secondary-border-subtle: rgb(193, 159.4, 249.4);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(80, 12.8, 192.8);
  --bs-secondary-hover-border: rgb(70, 11.2, 168.7);
  --bs-secondary-active-bg: rgb(80, 12.8, 192.8);
  --bs-secondary-active-border: rgb(70, 11.2, 168.7);
  --bs-success: #33C889;
  --bs-success-rgb: 51, 200, 137;
  --bs-success-bg-subtle: rgb(214.2, 244, 231.4);
  --bs-success-border-subtle: rgb(173.4, 233, 207.8);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(40.8, 160, 109.6);
  --bs-success-hover-border: rgb(35.7, 140, 95.9);
  --bs-success-active-bg: rgb(40.8, 160, 109.6);
  --bs-success-active-border: rgb(35.7, 140, 95.9);
  --bs-warning: #E76F51;
  --bs-warning-rgb: 231, 111, 81;
  --bs-warning-bg-subtle: rgb(250.2, 226.2, 220.2);
  --bs-warning-border-subtle: rgb(245.4, 197.4, 185.4);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(184.8, 88.8, 64.8);
  --bs-warning-hover-border: rgb(161.7, 77.7, 56.7);
  --bs-warning-active-bg: rgb(184.8, 88.8, 64.8);
  --bs-warning-active-border: rgb(161.7, 77.7, 56.7);
  --bs-danger: #FF5959;
  --bs-danger-rgb: 255, 89, 89;
  --bs-danger-bg-subtle: rgb(255, 221.8, 221.8);
  --bs-danger-border-subtle: rgb(255, 188.6, 188.6);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(204, 71.2, 71.2);
  --bs-danger-hover-border: rgb(178.5, 62.3, 62.3);
  --bs-danger-active-bg: rgb(204, 71.2, 71.2);
  --bs-danger-active-border: rgb(178.5, 62.3, 62.3);
  --bs-info: #64ABC6;
  --bs-info-rgb: 100, 171, 198;
  --bs-info-bg-subtle: rgb(224, 238.2, 243.6);
  --bs-info-border-subtle: rgb(193, 221.4, 232.2);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(80, 136.8, 158.4);
  --bs-info-hover-border: rgb(70, 119.7, 138.6);
  --bs-info-active-bg: rgb(80, 136.8, 158.4);
  --bs-info-active-border: rgb(70, 119.7, 138.6);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-gray: #A3A3A3;
  --bs-gray-rgb: 163, 163, 163;
  --bs-gray-bg-subtle: rgb(236.6, 236.6, 236.6);
  --bs-gray-border-subtle: rgb(218.2, 218.2, 218.2);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(130.4, 130.4, 130.4);
  --bs-gray-hover-border: rgb(114.1, 114.1, 114.1);
  --bs-gray-active-bg: rgb(130.4, 130.4, 130.4);
  --bs-gray-active-border: rgb(114.1, 114.1, 114.1);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(242.4, 165.6, 112.8);
  --bs-primary-bg-subtle: rgb(46.8, 21.2, 3.6);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(162, 111.6, 246.6);
  --bs-secondary-bg-subtle: rgb(20, 3.2, 48.2);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(132.6, 222, 184.2);
  --bs-success-bg-subtle: rgb(10.2, 40, 27.4);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(240.6, 168.6, 150.6);
  --bs-warning-bg-subtle: rgb(46.2, 22.2, 16.2);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(255, 155.4, 155.4);
  --bs-danger-bg-subtle: rgb(51, 17.8, 17.8);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(162, 204.6, 220.8);
  --bs-info-bg-subtle: rgb(20, 34.2, 39.6);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(199.8, 199.8, 199.8);
  --bs-gray-bg-subtle: rgb(32.6, 32.6, 32.6);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-4][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}

[data-bs-theme-color=color-5] {
  --bs-primary: #E586B3;
  --bs-primary-rgb: 229, 134, 179;
  --bs-primary-bg-subtle: rgb(249.8, 230.8, 239.8);
  --bs-primary-border-subtle: rgb(244.6, 206.6, 224.6);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-primary-hover-bg: rgb(183.2, 107.2, 143.2);
  --bs-primary-hover-border: rgb(160.3, 93.8, 125.3);
  --bs-primary-active-bg: rgb(183.2, 107.2, 143.2);
  --bs-primary-active-border: rgb(160.3, 93.8, 125.3);
  --bs-secondary: #25C799;
  --bs-secondary-rgb: 37, 199, 153;
  --bs-secondary-bg-subtle: rgb(211.4, 243.8, 234.6);
  --bs-secondary-border-subtle: rgb(167.8, 232.6, 214.2);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-secondary-hover-bg: rgb(29.6, 159.2, 122.4);
  --bs-secondary-hover-border: rgb(25.9, 139.3, 107.1);
  --bs-secondary-active-bg: rgb(29.6, 159.2, 122.4);
  --bs-secondary-active-border: rgb(25.9, 139.3, 107.1);
  --bs-success: #23A16C;
  --bs-success-rgb: 35, 161, 108;
  --bs-success-bg-subtle: rgb(211, 236.2, 225.6);
  --bs-success-border-subtle: rgb(167, 217.4, 196.2);
  --bs-link-color-rgb: var(--bs-success-rgb);
  --bs-success-hover-bg: rgb(28, 128.8, 86.4);
  --bs-success-hover-border: rgb(24.5, 112.7, 75.6);
  --bs-success-active-bg: rgb(28, 128.8, 86.4);
  --bs-success-active-border: rgb(24.5, 112.7, 75.6);
  --bs-warning: #E97B20;
  --bs-warning-rgb: 233, 123, 32;
  --bs-warning-bg-subtle: rgb(250.6, 228.6, 210.4);
  --bs-warning-border-subtle: rgb(246.2, 202.2, 165.8);
  --bs-link-color-rgb: var(--bs-warning-rgb);
  --bs-warning-hover-bg: rgb(186.4, 98.4, 25.6);
  --bs-warning-hover-border: rgb(163.1, 86.1, 22.4);
  --bs-warning-active-bg: rgb(186.4, 98.4, 25.6);
  --bs-warning-active-border: rgb(163.1, 86.1, 22.4);
  --bs-danger: #D64141;
  --bs-danger-rgb: 214, 65, 65;
  --bs-danger-bg-subtle: rgb(246.8, 217, 217);
  --bs-danger-border-subtle: rgb(238.6, 179, 179);
  --bs-link-color-rgb: var(--bs-danger-rgb);
  --bs-danger-hover-bg: rgb(171.2, 52, 52);
  --bs-danger-hover-border: rgb(149.8, 45.5, 45.5);
  --bs-danger-active-bg: rgb(171.2, 52, 52);
  --bs-danger-active-border: rgb(149.8, 45.5, 45.5);
  --bs-info: #69CBF0;
  --bs-info-rgb: 105, 203, 240;
  --bs-info-bg-subtle: rgb(225, 244.6, 252);
  --bs-info-border-subtle: rgb(195, 234.2, 249);
  --bs-link-color-rgb: var(--bs-info-rgb);
  --bs-info-hover-bg: rgb(84, 162.4, 192);
  --bs-info-hover-border: rgb(73.5, 142.1, 168);
  --bs-info-active-bg: rgb(84, 162.4, 192);
  --bs-info-active-border: rgb(73.5, 142.1, 168);
  --bs-light: #dee2e6;
  --bs-light-rgb: 222, 226, 230;
  --bs-light-bg-subtle: rgb(248.4, 249.2, 250);
  --bs-light-border-subtle: rgb(241.8, 243.4, 245);
  --bs-link-color-rgb: var(--bs-light-rgb);
  --bs-light-hover-bg: rgb(177.6, 180.8, 184);
  --bs-light-hover-border: rgb(155.4, 158.2, 161);
  --bs-light-active-bg: rgb(177.6, 180.8, 184);
  --bs-light-active-border: rgb(155.4, 158.2, 161);
  --bs-dark: #22292E;
  --bs-dark-rgb: 34, 41, 46;
  --bs-dark-bg-subtle: rgb(210.8, 212.2, 213.2);
  --bs-dark-border-subtle: rgb(166.6, 169.4, 171.4);
  --bs-link-color-rgb: var(--bs-dark-rgb);
  --bs-dark-hover-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-hover-border: rgb(23.8, 28.7, 32.2);
  --bs-dark-active-bg: rgb(27.2, 32.8, 36.8);
  --bs-dark-active-border: rgb(23.8, 28.7, 32.2);
  --bs-gray: #949494;
  --bs-gray-rgb: 148, 148, 148;
  --bs-gray-bg-subtle: rgb(233.6, 233.6, 233.6);
  --bs-gray-border-subtle: rgb(212.2, 212.2, 212.2);
  --bs-link-color-rgb: var(--bs-gray-rgb);
  --bs-gray-hover-bg: rgb(118.4, 118.4, 118.4);
  --bs-gray-hover-border: rgb(103.6, 103.6, 103.6);
  --bs-gray-active-bg: rgb(118.4, 118.4, 118.4);
  --bs-gray-active-border: rgb(103.6, 103.6, 103.6);
  --bs-gray-dark: #001F4D;
  --bs-gray-dark-rgb: 0, 31, 77;
  --bs-gray-dark-bg-subtle: rgb(204, 210.2, 219.4);
  --bs-gray-dark-border-subtle: rgb(153, 165.4, 183.8);
  --bs-link-color-rgb: var(--bs-gray-dark-rgb);
  --bs-gray-dark-hover-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-hover-border: rgb(0, 21.7, 53.9);
  --bs-gray-dark-active-bg: rgb(0, 24.8, 61.6);
  --bs-gray-dark-active-border: rgb(0, 21.7, 53.9);
  --bs-indigo: #009688;
  --bs-indigo-rgb: 0, 150, 136;
  --bs-indigo-bg-subtle: rgb(204, 234, 231.2);
  --bs-indigo-border-subtle: rgb(153, 213, 207.4);
  --bs-link-color-rgb: var(--bs-indigo-rgb);
  --bs-indigo-hover-bg: rgb(0, 120, 108.8);
  --bs-indigo-hover-border: rgb(0, 105, 95.2);
  --bs-indigo-active-bg: rgb(0, 120, 108.8);
  --bs-indigo-active-border: rgb(0, 105, 95.2);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-primary-text-emphasis: rgb(239.4, 182.4, 209.4);
  --bs-primary-bg-subtle: rgb(45.8, 26.8, 35.8);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-secondary-text-emphasis: rgb(124.2, 221.4, 193.8);
  --bs-secondary-bg-subtle: rgb(7.4, 39.8, 30.6);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-success-text-emphasis: rgb(123, 198.6, 166.8);
  --bs-success-bg-subtle: rgb(7, 32.2, 21.6);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-warning-text-emphasis: rgb(241.8, 175.8, 121.2);
  --bs-warning-bg-subtle: rgb(46.6, 24.6, 6.4);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-danger-text-emphasis: rgb(230.4, 141, 141);
  --bs-danger-bg-subtle: rgb(42.8, 13, 13);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-info-text-emphasis: rgb(165, 223.8, 246);
  --bs-info-bg-subtle: rgb(21, 40.6, 48);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-light-text-emphasis: rgb(235.2, 237.6, 240);
  --bs-light-bg-subtle: rgb(44.4, 45.2, 46);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-dark-text-emphasis: rgb(122.4, 126.6, 129.6);
  --bs-dark-bg-subtle: rgb(6.8, 8.2, 9.2);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-gray-text-emphasis: rgb(190.8, 190.8, 190.8);
  --bs-gray-bg-subtle: rgb(29.6, 29.6, 29.6);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-gray-dark-text-emphasis: rgb(102, 120.6, 148.2);
  --bs-gray-dark-bg-subtle: rgb(0, 6.2, 15.4);
}
[data-bs-theme-color=color-5][data-bs-theme=dark] {
  --bs-indigo-text-emphasis: rgb(102, 192, 183.6);
  --bs-indigo-bg-subtle: rgb(0, 30, 27.2);
}